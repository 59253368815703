<template>
<div>
<vfrom @submit="onSubmit"   >
   <BaseInfo :baseInfo="$store.state.user.baseInfo" />
    <h3 class="header">请选择你要推送的项目</h3>
   <vfield name="sids"  class="field">
            <template #input>
             <CheckboxGroup v-model="sids"   >
               <Checkbox class="line" v-for="(section ,idx) in sections" :key="idx"  shape="square"  :name="section.code" >
                 {{section.title}}
               </Checkbox>
             </CheckboxGroup>
            </template>
    </vfield>
  <div style="margin: 16px;">
    <Button round block type="info" native-type="submit" >提交</Button>
  </div>
  <RLFooter/>
</vfrom>
    
</div>

</template>

<script>
import {  Form as vfrom,Field as vfield,Button,Checkbox ,CheckboxGroup} from 'vant';
import {getquestus,pushques} from '@/api/ques';//
export default {
  name: 'pushque',
  components: {
      vfrom,vfield,Button,Checkbox ,CheckboxGroup
  },
  data(){
    return {
      sids:[],
      sections:[]
    };
  },
  methods:{
     onSubmit(values) {
         let params={pid:this.$route.params.pid};
         for(let key in values){
        if(typeof([])==typeof(values[key])){
            params[key]=values[key].join(",");
        }else{
          params[key]=values[key];
        }
      }
      pushques(params).then((response) => {
          if(response.code==200 ){
               //this.$toast.success('操作成功');
              if(window.JS2AndroidUtil){
                window.JS2AndroidUtil.clickAction('保存成功');
             }else{
                this.$router.push({path: '/success'});
             }
          }else{
              //this.$toast.fail('操作失败请稍候重试');
              if(window.JS2AndroidUtil){
                window.JS2AndroidUtil.clickAction('保存出错');
             }else{
                this.$router.push({path: '/err'});
             }
          }
    });
    },
  },
created() {
       getquestus({}).then((response) => {
            this.sections=response.data.sections;
       }); 
  },
}
</script>

<style scoped>
.line{
  margin-top:0.5em;
}
.header{

    margin-left: 1em;
    margin-top: 2em;
}
    
.field{
    margin-left: 2.5em;
}
</style>
